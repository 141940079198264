import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "components/ComponentHtml/Header";
import Navbar from "components/ComponentHtml/Navbar";
import HowItWorks from "components/ComponentHtml/HowItworks";
import TheAdvantages from "components/ComponentHtml/TheAdvantages";
import CardProduct from "components/ComponentHtml/CardProduct";
import InfoHome from "components/ComponentHtml/InfoHome";
import FooterOnePage from "components/ComponentHtml/FooterOnePage";
import ConsulenzaHome from "components/ComponentHtml/ConsulenzaHome";
import Faq from "components/ComponentHtml/Faq";

export default function Home() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    document.body.setAttribute("data-root", window.location.pathname);

    setTimeout(() => {
      setIsLoading(false);
    }, 100);

    return () => {
      document.body.removeAttribute("data-root");
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <div style={{ display: "none" }} />
      ) : (
        <>
          <Navbar />
          <Header />
          <HowItWorks />
          <TheAdvantages />
          <CardProduct />
          <Faq />
          <ConsulenzaHome />
          <InfoHome />
          <FooterOnePage />
        </>
      )}
    </>
  );
}
