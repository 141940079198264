import { useEffect } from "react";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import Routes from "./Routes";
import { history } from "./history";
import configureStore from "helpers/configureStore";
import { ThemeProvider } from "@mui/styles";
import { StyledEngineProvider } from "@mui/material";
import MuiTheme from "./theme";

import TagManager from "react-gtm-module";

import "./assets/app.scss";

const tagManagerArgs = {
  gtmId: "GTM-PZSSH5Z7",
};

TagManager.initialize(tagManagerArgs);

const store = configureStore();

function App() {
  useEffect(() => {
    const partnerEnv = process.env.REACT_APP_PARTNERSHIP;
    const partner = partnerEnv ? !!JSON.parse(partnerEnv) : false;

    if (!partner) {
      const script = document.createElement("script");

      script.src = "https://code.tidio.co/wwiccu68pwawmgqq8qyafoe7daqap8ek.js";
      script.async = true;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={MuiTheme}>
        <Provider store={store}>
          <Router history={history} basename="/">
            <Routes />
          </Router>
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
