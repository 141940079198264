import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import HeaderLogo from "components/ComponentHtml/HeaderLogo";
import FooterOnePage from "components/ComponentHtml/FooterOnePage";
import CookiePolicyComponent from "components/ComponentHtml/CookiePolicy";
import CookiePolicyPartner from "components/ComponentHtml/CookiePolicyPartner";

export default function CookiePolicy() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  const partnerEnv = process.env.REACT_APP_PARTNERSHIP;
  const partner = partnerEnv ? !!JSON.parse(partnerEnv) : false;

  useEffect(() => {
    document.body.setAttribute("data-root", window.location.pathname);

    setTimeout(() => {
      setIsLoading(false);
    }, 100);

    return () => {
      document.body.removeAttribute("data-root");
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <div style={{ display: "none" }} />
      ) : (
        <>
          <HeaderLogo />
          {partner ? <CookiePolicyPartner /> : <CookiePolicyComponent />}
          <FooterOnePage />
        </>
      )}
    </>
  );
}
