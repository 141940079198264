import React from "react";
import "assets/components/howItWorks.scss";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import { Grid } from "@mui/material";

const HowItWorksConsulenza = (props) => {
  return (
    <>
      <div id="come-funziona" className="container-section-consulenza">
        <div className="col-8 col-sm-12 col-lg-8 box2-section-3">
          <h1 className="title-section-3 text-light">Come funziona</h1>
          <h2 className="subtitle-section-3 text-light">
            Ricevere una consulenza penale personalizzata con noi è semplice e
            comodo
          </h2>
          <div className="bubbles-container">
            <div className="d-flex">
              <div
                className="bubble bubble-text-consulenza first-bubble"
                style={{ width: "80px" }}
              >
                {/* 01 */}1
              </div>
              <div className="mt-2 box-text">
                <p className="text-title-bubble p-0 m-0">Registrati</p>
                <p className="subtitle-title-bubble">
                  Crea il tuo account in meno di un minuto, o effettua il login
                  se sei già registrato
                </p>
              </div>
            </div>
            <div className="d-flex">
              <div
                className="bubble bubble-text-consulenza second-bubble"
                style={{ width: "80px" }}
              >
                {/* 02 */}2
              </div>
              <div className="box-text" style={{ marginTop: "-13px" }}>
                <p className="text-title-bubble p-0 m-0">
                  Inserisci il tuo quesito
                </p>
                <p className="subtitle-title-bubble">
                  Indica la tua domanda o racconta il tuo caso complesso. Poi
                  scegli data e ora del tuo appuntamento.
                </p>
              </div>
            </div>
            <div className="d-flex">
              <div
                className="bubble bubble-text-consulenza last-bubble three-bubble"
                style={{ width: "80px" }}
              >
                {/* 03 */}3
              </div>
              <div className="box-text" style={{ marginTop: "0px" }}>
                <p className="text-title-bubble p-0 m-0">
                  Parla con un avvocato penalista
                </p>
                <p className="subtitle-title-bubble">
                  Un professionista si collegherà con te in videocall. Discuterà
                  con te il caso e ti fornirà tutte le informazioni e i consigli
                  necessari.
                </p>
              </div>
            </div>

            <Grid container className="col-12 pb-5 box-button">
              <Grid item xs={2} sm={1.4} />
              <Grid
                item
                xs={10}
                sm={5}
                className="link-faq-margin"
                style={{ display: "flex", alignItems: "center" }}
              >
                <a href="#Faq" className="linkFaq">
                  Leggi le FAQ
                </a>
              </Grid>
              <Grid item xs={10} sm={5}>
                <a
                  href="/register"
                  className="btn button-section-3-consulenza box-button-inizia"
                >
                  Richiedi subito
                </a>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="img-section-3"></div>
      </div>
    </>
  );
};

export default HowItWorksConsulenza;
