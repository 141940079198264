import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import Navbar from "components/ComponentHtml/Navbar";
import HeaderConsulenza from "components/ComponentHtml/ConsulenzaComponent/HeaderConsulenza";
import HowItWorksConsulenza from "components/ComponentHtml/ConsulenzaComponent/HowItWorksConsulenza";
import TheAdvantagesConsulenza from "components/ComponentHtml/ConsulenzaComponent/TheAdvantagesConsulenza";
import CardProductConsulenza from "components/ComponentHtml/ConsulenzaComponent/CardProductConsulenza";
import InfoHomeConsulenza from "components/ComponentHtml/ConsulenzaComponent/InfoHomeConsulenza";
import FooterOnePage from "components/ComponentHtml/FooterOnePage";
import SectionDenuncia from "components/ComponentHtml/ConsulenzaComponent/SectionDenuncia";
import FaqConsulenza from "components/ComponentHtml/ConsulenzaComponent/FaqConsulenza";

export default function ConsulenzaLandingPage() {
  return (
    <>
      <Navbar />
      <HeaderConsulenza />
      <HowItWorksConsulenza />
      <TheAdvantagesConsulenza />
      <CardProductConsulenza />
      <FaqConsulenza />
      <SectionDenuncia />
      <InfoHomeConsulenza />
      <FooterOnePage />
    </>
  );
}
