import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Container,
  Checkbox,
  FormControlLabel,
  FormControl,
  Link,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../history";
import { actions } from "../../_model/report/actions";
import TextSimple from "components/TextSimple";
import FacebookLogin from "components/FacebookLogin";
import GoogleLogin from "components/GoogleLogin";
import Divider from "components/Divider";
import ButtonPrimary from "components/ButtonPrimary";
import GoogleLoginCustom from "components/GoogleLoginCustom";
import { Helmet } from "react-helmet";
import MailIcon from "@mui/icons-material/Mail";

export default function Register() {
  const customer = useSelector((state) => state.report.customer);
  const errors = useSelector((state) => state.report.errors);
  const dispatch = useDispatch();

  // variabile d'ambiente partner
  const partnerEnv = process.env.REACT_APP_PARTNERSHIP;
  const partner = partnerEnv ? !!JSON.parse(partnerEnv) : false;

  // gestione partner
  const [clientPartner, setClientPartner] = useState(null);
  const [id, setId] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    if (partner) {
      const url = new URL(window.location.href);
      const id = url.searchParams.get("id");
      const token = url.searchParams.get("token");
      if (id) {
        setId(id);
      }
      if (token) {
        setToken(token);
      }

      dispatch(actions.PartnerCustomerVerification(id, token))
        .then((data) => {
          if (data.data.allow_partnership === true) {
            setClientPartner(true);
          }

          if (data.data.allow_partnership === false) {
            setClientPartner(false);
          }
        })
        .catch((error) => {
          setClientPartner(false);
        });
    } else {
      setClientPartner(null);
    }
  }, [partner, dispatch]);

  //

  const registrationSuccess = useSelector((state) =>
    state.report.reportData && state.report.reportData.data
      ? state.report.reportData.data
      : {}
  );

  const [error, setError] = useState(false);
  const [boxInfoValidation, setBoxInfoValidation] = useState(false);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const handleChange = (event) => {
    event.persist();
    setError(false);
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleSignIn = async (event) => {
    const {
      name,
      email,
      password,
      password_confirmation,
      privacy,
      terms,
      additional_field,
    } = formState.values;

    var properties = {
      NAME: name,
      email_id: email,
    };
    try {
      setIsButtonDisabled(true);
      clientPartner === true
        ? await dispatch(
            actions.register(
              name,
              email,
              password,
              password_confirmation,
              privacy,
              terms,
              id,
              token,
              additional_field
            )
          )
        : await dispatch(
            actions.register(
              name,
              email,
              password,
              password_confirmation,
              privacy,
              terms
            )
          );

      partner === false
        ? (sendinblue.identify(email, {
            Nome: name,
            email_id: email,
          }),
          console.log("ok"),
          sendinblue.track("signup", properties))
        : null;
    } catch (error) {
      console.error("Errore durante l'invio:", error);
      setIsButtonDisabled(false);
    } finally {
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 10000);
    }
  };

  useEffect(() => {
    document.body.setAttribute("data-root", window.location.pathname);
    return () => {
      document.body.removeAttribute("data-root");
    };
  }, []);

  useEffect(() => {
    if (registrationSuccess.registered === true) {
      setBoxInfoValidation(true);

      setTimeout(() => {
        dispatch(actions.EmptyRegister());
      }, 10000);
    }

    if (
      registrationSuccess.registered === false &&
      boxInfoValidation === true
    ) {
      setBoxInfoValidation(false);
      history.push("/login");
    }
  }, [registrationSuccess.registered]);

  useEffect(() => {
    if (customer) {
      history.push("/dashboard");
    }
  }, [customer]);

  return (
    <>
      <Helmet>
        <title>DenunciaOnline.eu</title>
        <meta
          name="description"
          content="Il primo e unico portale italiano per presentare una denuncia completamente online."
        />
      </Helmet>
      {clientPartner === true ? (
        <Container
          className="PageLogin"
          maxWidth="sm"
          style={{ marginBottom: "0px" }}
        >
          {boxInfoValidation ? (
            <>
              <Grid
                item
                xs={12}
                style={{
                  backgroundColor: "#fff",
                  height: "150px",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid
                  item
                  xs={10}
                  style={{
                    maxWidth: "400px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    margin: "auto",
                    paddingBottom: "20px",
                  }}
                >
                  <div
                    style={{
                      paddingTop: "20px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <MailIcon
                      style={{
                        fontSize: "30px",
                        color: "#58979d",
                      }}
                    />
                    <Typography
                      style={{
                        fontSize: "20px",
                        fontWeight: 600,
                        color: "#20305b",
                        marginLeft: "10px",
                      }}
                    >
                      Verifica la tua email
                    </Typography>
                  </div>
                  <Typography
                    style={{
                      fontSize: "16px",
                      color: "#20305b",
                      fontWeight: 400,
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      marginTop: "10px",
                    }}
                  >
                    Controlla la tua casella di posta elettronica per confermare
                    la validità del tuo indirizzo.
                  </Typography>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid
              container
              rowSpacing={3}
              justifyContent="center"
              textAlign="center"
            >
              {partner === true ? (
                <>
                  {" "}
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        color: "#ffffff",
                        fontWeight: 400,
                      }}
                    >
                      Crea un account
                    </Typography>
                  </Grid>
                </>
              ) : (
                <>
                  {" "}
                  <Grid
                    container
                    className="containerButton"
                    style={{ marginTop: "20px" }}
                  >
                    <Grid className="containerGoogle" item xs={12} sm={5}>
                      <GoogleLoginCustom />
                    </Grid>
                    <Grid className="containerFacebook" item xs={12} sm={5}>
                      <FacebookLogin />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        color: "#ffffff",
                        fontWeight: 400,
                        marginBottom: "10px",
                        marginTop: "-20px",
                      }}
                    >
                      oppure
                    </Typography>
                    <Typography
                      style={{
                        color: "#ffffff",
                        fontWeight: 400,
                      }}
                    >
                      Crea un account
                    </Typography>
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <TextField
                  id="name"
                  placeholder="Nominativo"
                  variant="outlined"
                  name="name"
                  fullWidth
                  onChange={handleChange}
                  value={formState.values.name}
                  helperText={errors && errors.name}
                  FormHelperTextProps={{
                    classes: {
                      root: "redHelperText",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  helperText={errors && errors.email}
                  id="email"
                  placeholder="Email"
                  variant="outlined"
                  name="email"
                  fullWidth
                  onChange={handleChange}
                  value={formState.values.email}
                  FormHelperTextProps={{
                    classes: {
                      root: "redHelperText",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="additional_field"
                  fullWidth
                  name="additional_field"
                  value={formState.values.additional_field}
                  onChange={handleChange}
                  inputProps={{
                    maxLength: 70,
                  }}
                  placeholder="Numero polizza/ Numero di sinistro DAS"
                  FormHelperTextProps={{
                    classes: {
                      root: "redHelperText",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  type="password"
                  fullWidth
                  name="password"
                  value={formState.values.password}
                  onChange={handleChange}
                  placeholder="Password"
                  FormHelperTextProps={{
                    classes: {
                      root: "redHelperText",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  type="password"
                  helperText={errors && errors.password}
                  fullWidth
                  name="password_confirmation"
                  value={formState.values.password_confirmation}
                  onChange={handleChange}
                  placeholder="Conferma Password"
                  FormHelperTextProps={{
                    classes: {
                      root: "redHelperText",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} display="flex" textAlign="justify">
                <FormControl>
                  <FormControlLabel
                    fullWidth
                    value="T&C-flag"
                    control={
                      <>
                        <Checkbox
                          name="terms"
                          style={{
                            color: "#ffffff",
                          }}
                          onChange={handleChange}
                          value="1"
                        />
                      </>
                    }
                    label={
                      <Typography
                        style={{
                          color: "#ffffff",
                          fontSize: "14px",
                        }}
                      >
                        Ho letto e accetto integralmente i{" "}
                        <a
                          href="/terms-conditions"
                          target="_blank"
                          style={{
                            color: "#ffffff",
                            marginRight: "2px",
                          }}
                        >
                          Termini e condizioni.
                        </a>
                      </Typography>
                    }
                    labelPlacement="end"
                  />

                  <FormControlLabel
                    fullWidth
                    value="policy-flag"
                    control={
                      <>
                        <Checkbox
                          name="privacy"
                          style={{
                            color: "#ffffff",
                          }}
                          onChange={handleChange}
                          value="2"
                        />
                      </>
                    }
                    label={
                      <Typography
                        style={{
                          color: "#ffffff",
                          fontSize: "14px",
                        }}
                      >
                        Ho letto e accetto integralmente l'informativa sulla{" "}
                        <a
                          href="/privacy"
                          target="_blank"
                          style={{
                            color: "#ffffff",
                            marginLeft: "1px",
                          }}
                        >
                          privacy
                        </a>
                        .
                      </Typography>
                    }
                    labelPlacement="end"
                  />

                  {errors && (errors.privacy || errors.terms) && (
                    <Typography
                      style={{
                        color: "#ffdfdf",
                        fontSize: "14px",
                      }}
                    >
                      È necessario accettare sia i Termini e condizioni sia
                      l'informativa privacy.
                    </Typography>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <ButtonPrimary
                  disabled={isButtonDisabled}
                  className="button-custom"
                  onClick={handleSignIn}
                >
                  Registrati
                </ButtonPrimary>
              </Grid>

              <Grid item xs={12}>
                <Typography style={{ color: "#ffffff" }}>
                  Hai già un account? Effettua il{" "}
                  <Link
                    href="/login"
                    style={{
                      color: "#ffffff",
                      textDecoration: "underline",
                      textDecorationColor: "#ffffff",
                    }}
                  >
                    Login
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          )}
        </Container>
      ) : clientPartner === false ? (
        <>
          <Grid
            container
            style={{ display: "flex", justifyContent: "center" }}
            className="modal-partner-error"
          >
            <Grid
              item
              xs={10}
              sm={8}
              md={6}
              style={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                padding: "20px",
                height: "140px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography style={{ fontSize: "18px", color: "#20305b" }}>
                Sembra che tu non sia autorizzato a utilizzare questo servizio
                in partnership con DAS. Ti invitiamo a contattare il supporto
                clienti di DAS per verificare la tua utenza assicurativa.
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : (
        <Container
          className="PageLogin"
          maxWidth="sm"
          style={{ marginBottom: "0px" }}
        >
          {boxInfoValidation ? (
            <>
              <Grid
                item
                xs={12}
                style={{
                  backgroundColor: "#fff",
                  height: "150px",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid
                  item
                  xs={10}
                  style={{
                    maxWidth: "400px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    margin: "auto",
                    paddingBottom: "20px",
                  }}
                >
                  <div
                    style={{
                      paddingTop: "20px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <MailIcon
                      style={{
                        fontSize: "30px",
                        color: "#58979d",
                      }}
                    />
                    <Typography
                      style={{
                        fontSize: "20px",
                        fontWeight: 600,
                        color: "#20305b",
                        marginLeft: "10px",
                      }}
                    >
                      Verifica la tua email
                    </Typography>
                  </div>
                  <Typography
                    style={{
                      fontSize: "16px",
                      color: "#20305b",
                      fontWeight: 400,
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      marginTop: "10px",
                    }}
                  >
                    Controlla la tua casella di posta elettronica per confermare
                    la validità del tuo indirizzo.
                  </Typography>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid
              container
              rowSpacing={3}
              justifyContent="center"
              textAlign="center"
            >
              <Grid
                container
                className="containerButton"
                style={{ marginTop: "20px" }}
              >
                <Grid className="containerGoogle" item xs={12} sm={5}>
                  <GoogleLoginCustom />
                </Grid>
                <Grid className="containerFacebook" item xs={12} sm={5}>
                  <FacebookLogin />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  style={{
                    color: "#ffffff",
                    fontWeight: 400,
                    marginBottom: "10px",
                    marginTop: "-20px",
                  }}
                >
                  oppure
                </Typography>
                <Typography
                  style={{
                    color: "#ffffff",
                    fontWeight: 400,
                  }}
                >
                  Crea un account
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="name"
                  placeholder="Nominativo"
                  variant="outlined"
                  name="name"
                  fullWidth
                  onChange={handleChange}
                  value={formState.values.name}
                  helperText={errors && errors.name}
                  FormHelperTextProps={{
                    classes: {
                      root: "redHelperText",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  helperText={errors && errors.email}
                  id="email"
                  placeholder="Email"
                  variant="outlined"
                  name="email"
                  fullWidth
                  onChange={handleChange}
                  value={formState.values.email}
                  FormHelperTextProps={{
                    classes: {
                      root: "redHelperText",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  type="password"
                  fullWidth
                  name="password"
                  value={formState.values.password}
                  onChange={handleChange}
                  placeholder="Password"
                  FormHelperTextProps={{
                    classes: {
                      root: "redHelperText",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  type="password"
                  helperText={errors && errors.password}
                  fullWidth
                  name="password_confirmation"
                  value={formState.values.password_confirmation}
                  onChange={handleChange}
                  placeholder="Conferma Password"
                  FormHelperTextProps={{
                    classes: {
                      root: "redHelperText",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} display="flex" textAlign="justify">
                <FormControl>
                  <FormControlLabel
                    fullWidth
                    value="T&C-flag"
                    control={
                      <>
                        <Checkbox
                          name="terms"
                          style={{
                            color: "#ffffff",
                          }}
                          onChange={handleChange}
                          value="1"
                        />
                      </>
                    }
                    label={
                      <Typography
                        style={{
                          color: "#ffffff",
                          fontSize: "14px",
                        }}
                      >
                        Ho letto e accetto integralmente i{" "}
                        <a
                          href="/terms-conditions"
                          target="_blank"
                          style={{
                            color: "#ffffff",
                            marginRight: "2px",
                          }}
                        >
                          Termini e condizioni.
                        </a>
                      </Typography>
                    }
                    labelPlacement="end"
                  />

                  <FormControlLabel
                    fullWidth
                    value="policy-flag"
                    control={
                      <>
                        <Checkbox
                          name="privacy"
                          style={{
                            color: "#ffffff",
                          }}
                          onChange={handleChange}
                          value="2"
                        />
                      </>
                    }
                    label={
                      <Typography
                        style={{
                          color: "#ffffff",
                          fontSize: "14px",
                        }}
                      >
                        Ho letto e accetto integralmente l'informativa sulla{" "}
                        <a
                          href="/privacy"
                          target="_blank"
                          style={{
                            color: "#ffffff",
                            marginLeft: "1px",
                          }}
                        >
                          privacy
                        </a>
                        .
                      </Typography>
                    }
                    labelPlacement="end"
                  />

                  {errors && (errors.privacy || errors.terms) && (
                    <Typography
                      style={{
                        color: "#ffdfdf",
                        fontSize: "14px",
                      }}
                    >
                      È necessario accettare sia i Termini e condizioni sia
                      l'informativa privacy.
                    </Typography>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <ButtonPrimary
                  disabled={isButtonDisabled}
                  className="button-custom"
                  onClick={handleSignIn}
                >
                  Registrati
                </ButtonPrimary>
              </Grid>

              <Grid item xs={12}>
                <Typography style={{ color: "#ffffff" }}>
                  Hai già un account? Effettua il{" "}
                  <Link
                    href="/login"
                    style={{
                      color: "#ffffff",
                      textDecoration: "underline",
                      textDecorationColor: "#ffffff",
                    }}
                  >
                    Login
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          )}
        </Container>
      )}
    </>
  );
}
