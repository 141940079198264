import React, { useEffect, useState } from "react";
import { Container, Grid, Typography } from "@mui/material";
import { actions } from "_model/report";
import { useDispatch, useSelector } from "react-redux";
import ReportDataCard from "components/ReportDataCard";
import TextHeaderPrimary from "components/TextHeaderPrimary";

import CalendlyReportCard from "components/CalendlyReportCard";

export default function PratichePage() {
  const dispatch = useDispatch();
  const reports = useSelector((state) => state.report.reports);
  const customer = useSelector((state) => state.report.customer);

  const memos = useSelector((state) => state.report.customer.memos);

  // state per brevo
  // const [previousStatuses, setPreviousStatuses] = useState({});

  // useEffect(() => {
  //   if (customer && reports.length > 0) {
  //     reports.forEach((report) => {
  //       const previousStatus = previousStatuses[report.id];

  //       if (report.status === "COMPLETE" && previousStatus === "NEW_DOCUMENT") {
  //         const properties = {
  //           NAME: customer.name,
  //           email_id: customer.email,
  //         };
  //         sendinblue.track("complete-denuncia", properties);
  //       }

  //       setPreviousStatuses((prev) => ({
  //         ...prev,
  //         [report.id]: report.status,
  //       }));
  //     });
  //   }
  // }, [reports, previousStatuses, customer]);

  useEffect(() => {
    dispatch(actions.loadReports());
  }, []);

  const [reportPending, setReportPending] = useState([]);
  const [reportCompleted, setReportCompleted] = useState([]);

  useEffect(() => {
    const completed = reports.filter((r) => "COMPLETE" === r.status);
    const pending = reports.filter((r) => "COMPLETE" !== r.status);
    setReportCompleted(completed);
    setReportPending(pending);
  }, [reports]);

  return (
    <>
      <Container
        sx={{
          height: "100%",
          overflow: "auto",
        }}
      >
        <Grid
          container
          marginBottom={4}
          rowSpacing={3}
          justifyContent="center"
          textAlign="left"
        >
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <a
              href={"/dashboard"}
              style={{
                color: "#fff",
                marginTop: "5px",
              }}
            >
              <div className="icon-back-dashoard" />
            </a>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <TextHeaderPrimary>Le tue pratiche</TextHeaderPrimary>
          </Grid>

          {reportPending.length > 0 && (
            <>
              <Grid marginTop={4} item xs={12}>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "18px",
                    fontWeight: 700,
                  }}
                >
                  Denunce da completare
                </Typography>
              </Grid>
              {reportPending.map((report, k) => {
                return (
                  <Grid key={k} item xs={12}>
                    <ReportDataCard report={report} showCta />
                  </Grid>
                );
              })}
            </>
          )}

          {reportCompleted.length > 0 && (
            <>
              <Grid marginTop={4} item xs={12}>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "16px",
                    fontWeight: 700,
                  }}
                >
                  Denunce presentate
                </Typography>
              </Grid>
              {reportCompleted.map((report, k) => {
                return (
                  <Grid item xs={12}>
                    <ReportDataCard report={report} showCta />
                  </Grid>
                );
              })}
            </>
          )}

          {memos && memos.length > 0 && (
            <>
              <Grid marginTop={4} item xs={12}>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "18px",
                    fontWeight: 700,
                  }}
                >
                  Consulenze
                </Typography>
              </Grid>
              {[...memos].reverse().map((memo, k) => (
                <Grid key={k} item xs={12}>
                  <CalendlyReportCard memo={memo} showCta />
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </Container>
    </>
  );
}
