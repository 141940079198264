import React, { useEffect } from "react";
import { actions } from "../_model/report/actions";
import { useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { Button, Grid, Typography } from "@mui/material";

const GoogleLoginCustom = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const loadGoogleScript = () => {
      if (!window.google) {
        const script = document.createElement("script");
        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;
        script.defer = true;
        script.onload = initializeGoogleLogin;
        document.body.appendChild(script);
      } else {
        initializeGoogleLogin();
      }
    };

    const initializeGoogleLogin = () => {
      google.accounts.id.initialize({
        client_id:
          "716000132879-d1uc14vco2asf89b2pbetko6dsr0mvig.apps.googleusercontent.com",
        callback: handleCallbackResponse,
      });

      const googleButtonWrapper = createFakeGoogleWrapper();
      const customGoogleButton = document.getElementById("customGoogleButton");
      customGoogleButton.addEventListener("click", () => {
        googleButtonWrapper.click();
      });
    };

    loadGoogleScript();
  }, []);

  function handleCallbackResponse(response) {
    const data = jwtDecode(response.credential);
    dispatch(actions.socialLogin(data.email, data.name, "google", data.sub));
  }

  const createFakeGoogleWrapper = () => {
    const googleLoginWrapper = document.createElement("div");
    googleLoginWrapper.style.display = "none";
    googleLoginWrapper.classList.add("custom-google-button");
    document.body.appendChild(googleLoginWrapper);

    window.google.accounts.id.renderButton(googleLoginWrapper, {});

    const googleLoginWrapperButton = googleLoginWrapper.querySelector(
      "div[role=button]"
    );

    return {
      click: () => {
        googleLoginWrapperButton.click();
      },
    };
  };

  return (
    <>
      <div className="GoogleLogin" id="customGoogleButton">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <svg
            width="27"
            height="28"
            viewBox="0 0 27 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.65116 13.814C5.65116 12.9349 5.77674 12.0558 6.0907 11.2395L1.44419 7.66048C0.502326 9.5442 0 11.6163 0 13.814C0 16.0116 0.502326 18.0837 1.44419 19.9047L6.0907 16.3256C5.77674 15.5721 5.65116 14.693 5.65116 13.814Z"
              fill="#FBBC05"
            />
            <path
              d="M13.8139 5.65116C15.7604 5.65116 17.5185 6.34186 18.8999 7.47209L22.9185 3.45349C20.4697 1.3186 17.3301 0 13.8139 0C8.35107 0 3.64177 3.13953 1.44409 7.72326L6.0906 11.3023C7.15805 7.97442 10.172 5.65116 13.8139 5.65116Z"
              fill="#EA4335"
            />
            <path
              d="M13.8139 21.9767C10.172 21.9767 7.15805 19.6535 6.0906 16.3884L1.44409 19.9674C3.64177 24.4884 8.35107 27.6279 13.8139 27.6279C17.2046 27.6279 20.4069 26.4349 22.7929 24.1744L18.3976 20.7837C17.1418 21.5372 15.572 21.9767 13.8139 21.9767Z"
              fill="#34A853"
            />
            <path
              d="M13.814 11.3023V16.6395H21.2233C20.8465 18.4605 19.8419 19.8419 18.3977 20.7837L22.793 24.1744C25.3047 21.7884 27 18.3349 27 13.814C27 12.9977 26.8744 12.1186 26.6861 11.3023H13.814Z"
              fill="#4285F4"
            />
          </svg>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography className="textButton">Accedi con Google</Typography>
          </div>
        </div>
      </div>
    </>
  );
};

export default GoogleLoginCustom;
