import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import "assets/components/SectionDenunciaInConsulenza.scss";
import animationData from "assets/img/animation-one.json";
import Lottie from "lottie-web";

const SectionDenuncia = () => {
  useEffect(() => {
    const container = document.getElementById("lottie-animation-denuncia");
    const animation = Lottie.loadAnimation({
      container: container,
      renderer: "svg",
      animationData: animationData,
      loop: true,
      autoplay: true,
    });

    return () => {
      animation.destroy();
    };
  }, []);

  return (
    <Grid container className="container-denuncia-minorSection">
      <Grid
        sx={{ order: { xs: 2, md: 1 } }}
        item
        xs={10}
        md={5}
        className="container-text-denuncia-minorSection"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            className="titleSection color1"
            style={{ fontWeight: 600 }}
          >
            Vuoi presentare subito una denuncia?
          </Typography>
          <p className="color1 mt-3">
            Sei stato vittima di un furto, una truffa, un danno o altri reati?
            Non perdere tempo e denuncia subito l'accaduto. Oggi puoi farlo
            direttamente online, dal tuo pc o smartphone, in meno di 5 minuti.
          </p>
          <a
            href="/"
            className="color1 link-margin"
            style={{ fontWeight: "bold" }}
          >
            Scopri di più
          </a>
        </div>
      </Grid>

      <Grid
        sx={{ order: { xs: 1, md: 2 } }}
        item
        xs={12}
        md={6}
        className="cover-image-denuncia-minorSection"
      >
        <div id="lottie-animation-denuncia"></div>
      </Grid>
    </Grid>
  );
};

export default SectionDenuncia;
