import React, { useEffect } from "react";
import "assets/components/faqConsulenza.scss";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import { Grid } from "@mui/material";

const FaqConsulenza = (props) => {
  return (
    <>
      <div id="Faq" className="container container-section-6-consulenza">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10">
            <h1 className="color1 title-section-2 ms-3">Faq</h1>
            <h2 className="color5 fs-4 ms-3" style={{ fontWeight: 700 }}>
              Chiariamo tutti i dubbi riguardo Denuncia Online
            </h2>
            <div className="accordion mt-5" id="accordionExample">
              <div className="accordion-item color1">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Come funziona nel dettaglio la consulenza?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      <strong>
                        La procedura è semplicissima, i passaggi sono i
                        seguenti:
                      </strong>
                      <br />
                    </p>
                    <p className="my-2">
                      <strong>1. Accesso:</strong>
                      <br />
                      Registrarti con il tuo indirizzo email o accedere con il
                      tuo account social.
                      <br />
                    </p>

                    <p className="my-2">
                      <strong>
                        2. Scelta dell’appuntamento e indicazione del quesito:
                      </strong>
                      <br />
                      Nella tua dashboard puoi selezionare giorno e ora in cui
                      ricevere la consulenza. Avrai anche un campo a tua
                      disposizione per scrivere il quesito o descrivere il caso.
                      <br />
                    </p>

                    <p className="my-2">
                      <strong>3. Consulenza in videocall con avvocato:</strong>
                      <br />
                      Il giorno dell’appuntamento un avvocato si collegherà in
                      videocall con te per risolvere il tuo quesito e fornirti
                      tutti i consigli utili, anche su eventuali ulteriori
                      azioni da intraprendere.
                      <br />
                    </p>

                    <p className="my-2">
                      <strong> 4. Eventuali step successivi:</strong>
                      <br />
                      Se insieme all’avvocato deciderai di proseguire con
                      attività successive alla consulenza (es. redazione di
                      diffide, querele, documenti, incarichi processuali) ti
                      sarà data ogni indicazione in merito.
                      <br />
                    </p>
                  </div>
                </div>
              </div>

              <div className="accordion-item color1">
                <h2 className="accordion-header" id="headingTen">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTen"
                    aria-expanded="false"
                    aria-controls="collapseTen"
                  >
                    Su cosa posso richiedere consulenza?
                  </button>
                </h2>
                <div
                  id="collapseTen"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTen"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Trattiamo principalmente temi giuridici di natura penale. Se
                    avessi bisogno di approfondire temi civilistici, sapremo
                    comunque indicarti la strada migliore per il tuo caso.
                  </div>
                </div>
              </div>

              <div className="accordion-item color1">
                <h2 className="accordion-header" id="headingFour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Quanto dura una consulenza?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Nella tariffa standard sono inclusi 30 minuti di consulenza
                    e sono sufficienti perricevere una risposta soddisfacente al
                    tuo quesito. Se vorrai proseguire oltre questotempo per
                    qualunque ragione, potrai accordarti direttamente con
                    l’avvocato che tipresenterà un preventivo specifico per
                    proseguire con l’attività.
                  </div>
                </div>
              </div>

              <div className="accordion-item color1">
                <h2 className="accordion-header" id="headingFor">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFor"
                    aria-expanded="false"
                    aria-controls="collapseFor"
                  >
                    La consulenza è interamente online?
                  </button>
                </h2>
                <div
                  id="collapseFor"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFor"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Sì, non devi recarti fisicamente in nessun ufficio. Avrai a
                    disposizione una videocall dedicata solo per te.
                  </div>
                </div>
              </div>

              <div className="accordion-item color1">
                <h2 className="accordion-header" id="headingFive">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Posso richiedere una consulenza urgente?
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Certamente! Se non trovi un appuntamento idoneo tramite la
                    nostra dashboard, scrivici in chat e faremo tutto il
                    possibile per darti un appuntamento entro le successive 24
                    ore.
                  </div>
                </div>
              </div>

              <div className="accordion-item color1">
                <h2 className="accordion-header" id="headingSix">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Quali sono i vantaggi della consulenza con DenunciaOnline?
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Con il nostro servizio puoi comodamente richiedere una
                    consulenza ovunque ti trovi. Eviti perdite di tempo per
                    spostamenti in città e non dovrai chiedere permessi o ferie
                    a lavoro. Quando in gioco c’è il diritto penale, spesso si
                    ha timore di parlare con un avvocato del proprio luogo
                    perché si vuole evitare di essere esposti in città. Con il
                    nostro servizio ti aiutiamo a superare anche questo timore,
                    con un team di professionisti qualificati di uno studio
                    legale a tua completa disposizione.
                  </div>
                </div>
              </div>

              <div className="accordion-item color1">
                <h2 className="accordion-header" id="headingSeven">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSeven"
                    aria-expanded="false"
                    aria-controls="collapseSeven"
                  >
                    Quali sono i passaggi successivi alla consulenza?
                  </button>
                </h2>
                <div
                  id="collapseSeven"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSeven"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    In genere, con i 30 minuti di consulenza vengono risolti i
                    quesiti che non richiedono ulteriore attività. Nel caso in
                    cui desiderassi richiedere all’avvocato le attività
                    successive suggerite o necessarie (es. redazione di diffide,
                    querele, documenti, incarichi processuali), ti daremo tutte
                    le indicazioni dovute presentandoti nel dettaglio i costi
                    aggiuntivi. Sarai sempre libero di accettare o meno il
                    preventivo proposto.
                  </div>
                </div>
              </div>

              <div className="accordion-item color1">
                <h2 className="accordion-header" id="headingEight">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseEight"
                    aria-expanded="false"
                    aria-controls="collapseEight"
                  >
                    Il servizio è sicuro?
                  </button>
                </h2>
                <div
                  id="collapseEight"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingEight"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Certamente! Tutti i tuoi dati rimarranno sempre protetti e
                    non saranno divulgati. Puoi fidarti di noi, prendiamo il
                    nostro mestiere molto seriamente.
                  </div>
                </div>
              </div>

              <div className="accordion-item color1">
                <h2 className="accordion-header" id="headingNine">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseNine"
                    aria-expanded="false"
                    aria-controls="collapseNine"
                  >
                    Il servizio prevede un abbonamento?
                  </button>
                </h2>
                <div
                  id="collapseNine"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingNine"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Assolutamente no, non ci sarà nessun addebito automatico.
                    Paghi solo per la singola consulenza acquistata e per gli
                    eventuali ulteriori servizi/attività di cui deciderai di
                    avvalerti.
                  </div>
                </div>
              </div>

              <div className="accordion-item color1">
                <h2 className="accordion-header" id="headingEleven">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseEleven"
                    aria-expanded="false"
                    aria-controls="collapseEleven"
                  >
                    Come posso pagare?
                  </button>
                </h2>
                <div
                  id="collapseEleven"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingEleven"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Puoi acquistare comodamente il servizio tramite PayPal
                    (carta o conto collegati). Siamo al lavoro per offrirti
                    presto nuovi metodi di pagamento.
                  </div>
                </div>
              </div>

              <div className="accordion-item color1">
                <h2 className="accordion-header" id="headingTwelve">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwelve"
                    aria-expanded="false"
                    aria-controls="collapseTwelve"
                  >
                    Se sono minorenne posso richiedere una consulenza?
                  </button>
                </h2>
                <div
                  id="collapseTwelve"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwelve"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Sì, purché tu abbia compiuto almeno 14 anni di età. In
                    alcuni casi potrebbe essere comunque necessaria la presenza
                    di un tuo parente o tutore.
                  </div>
                </div>
              </div>
            </div>

            <Grid container style={{ marginTop: "50px" }}>
              <Grid item xs={10} md={6} lg={5}>
                <a href="/register" className="btn btn-section-consulenza">
                  Richiedi subito
                </a>
              </Grid>
              <Grid item xs={10} md={6} lg={5}>
                {" "}
                <p
                  className="color1"
                  style={{
                    color: "#473c90",
                    marginTop: "30px",
                  }}
                >
                  Hai ancora dubbi?{" "}
                  <a
                    href="https://chatting.page/wwiccu68pwawmgqq8qyafoe7daqap8ek"
                    target="_blanck"
                    className="color1"
                    style={{
                      fontWeight: "bold",
                      color: "#473c90",
                      marginLeft: "5px",
                    }}
                  >
                    Scrivici in chat
                  </a>{" "}
                </p>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqConsulenza;
