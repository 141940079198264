import React, { useEffect } from "react";
import "assets/components/theAdvantages.scss";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import animationData from "assets/img/animation-3.json";
import Lottie from "lottie-web";

const TheAdvantagesConsulenza = (props) => {
  useEffect(() => {
    const container = document.getElementById("lottie-animation-2");
    const animation = Lottie.loadAnimation({
      container: container,
      renderer: "svg",
      animationData: animationData,
      loop: true,
      autoplay: true,
    });
    return () => {
      animation.destroy();
    };
  }, []);

  useEffect(() => {
    const container = document.getElementById("lottie-animation-2-smart");
    const animation = Lottie.loadAnimation({
      container: container,
      renderer: "svg",
      animationData: animationData,
      loop: true,
      autoplay: true,
    });
    return () => {
      animation.destroy();
    };
  }, []);
  return (
    <>
      <div id="I-vantaggi" className="container-fluid p-0 container-section-4">
        <div className="col-12 col-md-6">
          <div id="lottie-animation-2" className="container-animation-2"></div>
        </div>

        <div className="col-12 col-lg-6 col-custom box2-section-4">
          <h1 className="title-section-2 color1" style={{ fontWeight: 600 }}>
            I benefici
          </h1>
          <h2
            className="color5 subtitle-section-4"
            style={{ fontWeight: 600, width: "98%" }}
          >
            Perché scegliere la consulenza penale di Denuncia Online?
          </h2>
          <p className="color1 mt-3" style={{ width: "80%" }}>
            In Italia il mondo penale è tradizionalmente ancorato a una
            consulenza in presenza in cui l'avvocato riceve il cliente nel
            proprio studio. Ciò anche in considerazione della delicatezza delle
            questioni trattate.
          </p>
          <p className="color1 mt-2 mb-0" style={{ width: "80%" }}>
            Noi di <span style={{ fontWeight: "bold" }}>Denuncia Online</span>{" "}
            crediamo fortemente che i servizi resi da un avvocato possano
            mantenere alto il proprio valore anche digitalmente. Anzi, ogni
            giorno lavoriamo per un grande obiettivo:{" "}
            <span style={{ fontWeight: "bold" }}>
              essere immediatamente a disposizione di chi ha un bisogno!
            </span>{" "}
            <br />
          </p>
          <p
            className="color1 m-0"
            style={{ width: "80%", fontWeight: "bold" }}
          >
            Perché richiedere una consulenza penale sul nostro portale?
          </p>
          <div className="col-12 mt-3">
            <div
              id="lottie-animation-2-smart"
              className="container-animation-2-smart"
            ></div>
          </div>
          <div className="box3-section-4">
            <div className="row mt-4" style={{ width: "95%" }}>
              <div className="col-12 col-xl-6 col-custom margin-bottom-5">
                <div style={{ display: "flex" }}>
                  <div className="icon2-consulenza"></div>
                  <div className="ref"></div>
                </div>
                <h4 className="color5 fs-4 mt-2" style={{ fontWeight: "bold" }}>
                  Esperienza e competenza
                </h4>
                <p
                  className="color1"
                  style={{ fontSize: "16px", fontWeight: 500 }}
                >
                  I nostri professionisti collaborano con lo studio legale
                  LEXIA, rinomato per la sua eccellenza. Ogni consulenza è
                  gestita da{" "}
                  <span style={{ fontWeight: "bold" }}>
                    avvocati con anni di esperienza nel settore penale
                  </span>{" "}
                  che ti guideranno anche nelle situazioni più complesse.
                </p>
              </div>
              <div className="col-12 col-xl-6 col-custom margin-bottom-5">
                <div className="icon3-consulenza"></div>
                <h4 className="color5 fs-4 mt-2" style={{ fontWeight: "bold" }}>
                  Accessibilità
                </h4>
                <p
                  className="color1"
                  style={{ fontSize: "16px", fontWeight: 500 }}
                >
                  La nostra idea di consulenza è quella di fornire a tutti un{" "}
                  <span style={{ fontWeight: "bold" }}>
                    servizio di qualità a costi contenuti
                  </span>
                  . Possiamo dare supporto sia alle vittime di un reato sia ai
                  presunti autori.
                </p>
              </div>
            </div>
            <div className="row mt-2" style={{ width: "95%" }}>
              <div className="col-12 col-lg-6 col-custom margin-bottom-5">
                <div className="icon1-consulenza"></div>
                <h4 className="color5 fs-4 mt-2" style={{ fontWeight: "bold" }}>
                  Digitalizzazione
                </h4>
                <p
                  className="color1"
                  style={{ fontSize: "16px", fontWeight: 500 }}
                >
                  Superiamo le barriere tradizionali che sono spesso ostacolo
                  per chi ha bisogno di supporto.{" "}
                  <span style={{ fontWeight: "bold" }}>
                    Prenoti online, parli online, ricevi risposte online
                  </span>
                  . Ti crea imbarazzo recarti fisicamente da un avvocato del tuo
                  paese? Siamo qui per te.
                </p>
              </div>
              <div className="col-12 col-lg-6 col-custom margin-bottom-5">
                <div className="icon4-consulenza"></div>
                <h4 className="color5 fs-4 mt-2" style={{ fontWeight: "bold" }}>
                  Riservatezza e trasparenza
                </h4>
                <p
                  className="color1"
                  style={{ fontSize: "16px", fontWeight: 500 }}
                >
                  Sappiamo quanto sia importante la sicurezza dei tuoi dati
                  nelle questioni legali. Garantiamo che{" "}
                  <span style={{ fontWeight: "bold" }}>
                    ogni dettaglio della tua situazione sarà trattato con la
                    massima discrezione
                  </span>
                  . Siamo anche trasparenti: suggeriamo ciò che è meglio per te.
                </p>
              </div>
            </div>
          </div>
          <a className="btn btn-section-consulenza mt-3" href="/register">
            Richiedi subito
          </a>
        </div>
      </div>
    </>
  );
};

export default TheAdvantagesConsulenza;
